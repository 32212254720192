"use client";
import React, { useState, useRef, useEffect } from "react";
import TopLogo from "../../assets/img/HALI logo white 1.png";
import TopLogoText from "../../assets/img/HALI.png";
import AddBox from "../../assets/img/Add box.png";
import ChatBubble from "../../assets/img/Chat bubble.png";
import Analytics from "../../assets/img/Analytics.png";
// import Archive from "../../assets/img/Archive.png";
import Settings from "../../assets/img/Settings.png";
import DashboardLogo from "../../assets/img/Group 6.png";
import Sound from "../../assets/img/Vector (1).png";
import UserImage from "../../assets/img/Interviewer.png";
import OtherSound from "../../assets/img/OtherSound.png";
import Mic from "../../assets/img/Group 15.png";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import RecordIcon from "@mui/icons-material/MicRounded";
import {
  GetJobDetailInterviewQuestionsAndAnswers,
  GetLatestUserJobDetails,
  GetOpenAiAudioResponse,
  GetOpenAiSpeechResponse,
  GetOpenAiTextResponse,
  GetUser,
  GetUserLatestInterviews,
  RefreshLoginToken,
  SaveNewInterviewResponses,
} from "../../api";
import { userJobDescription } from "../../utils/jobDescription";
import ConfettiComponent from "../../helpers/Confetti";
import { toast } from "react-toastify";
import { ToastErrorComponents } from "../../App";
import { token } from "../../utils/token";
import { authUser } from "../../utils/user";
import Performance from "./performance";
import SettingsComponent from "./settings";

const Dashboard = () => {
  const navigate = useNavigate();

  const localMessages = JSON.parse(localStorage.getItem("messages")) ?? [];

  const [messages, setMessages] = useState(localMessages);
  const [user, setUser] = useState();

  const [userInput, setUserInput] = useState("");
  const [isAIWriting, setIsAIWriting] = useState(false);
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [showSend, setShowSend] = useState(false);
  const [aiIsTranscribing, setAiIsTranscribing] = useState(false);
  const [jobDetails, setJobDetails] = useState({
    _id: "",
    industry: "",
    jobPosition: "",
    seniorityLevel: "",
    background: "",
    areaOfExpertiseOrSkills: "",
    company: "",
    country: "",
    jobDescription: "",
  });
  const [hideTextArea, setHideTextArea] = useState(false);
  const [complete, setComplete] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [aiAudioText, setAiAudioText] = useState(null);
  const [aiSpeech, setAiSpeech] = useState(false);
  const [audioSource, setAudioSource] = useState(null);
  const [messageIndicatorIndex, setMessageIndicatorIndex] = useState(null);
  const [cvData, setCvData] = useState(null);
  const [showInterview, setShowInterview] = useState(true);
  const [showPerformance, setShowPerformance] = useState(false);
  const [showSettings, setShowSettings] = useState(false);

  const inputRef = useRef(null);
  const chatContainerRef = useRef(null);

  useEffect(() => {
    const getAuthUser = async () => {
      const userString = await authUser.get();
      setUser(JSON.parse(userString));
    };

    getAuthUser();
  }, [user]);

  useEffect(() => {
    // Scroll to the bottom when messages change
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, [messages, isAIWriting]);

  useEffect(() => {
    setTimeout(async () => {
      let jobDes = await userJobDescription.get();
      jobDes = typeof jobDes === "object" ? jobDes : JSON.parse(jobDes);
      if (jobDes) {
        setJobDetails(jobDes);
        getExistingInterviews();
        if (
          messages.length === 0 &&
          localStorage.getItem("completed") !== "true"
        ) {
          const aiGreeting = `Hello, welcome to the interview for the ${jobDes.jobPosition} role. Thank you for taking the time to meet with us today. Please be informed that we will ask you 10 most relevant questions for your role, your answer on each question will be evaluated in the Performance review. To start off, could you please introduce yourself and give us a brief overview of your background in ${jobDes.jobPosition}?`;
          messages.push({
            text: aiGreeting,
            user: "ai",
          });
          setMessages(messages);
          setAiAudioText(aiGreeting);
          setAiSpeech(true);
        }
      }
    }, 1000);
  }, []);

  // useEffect(() => {
  //   if (jobDetails._id !== "") {
  //     getExistingInterviews();
  //   }
  // }, [jobDetails]);

  useEffect(() => {
    if (messages.length >= 22) {
      setHideTextArea(true);
      localStorage.setItem("completed", true);
    }
  }, []);

  useEffect(() => {
    handleAudio();
  }, []);

  useEffect(() => {
    handleAiAudio();
  }, [aiAudioText, aiSpeech]);

  useEffect(() => {
    if (audioSource) {
      if (audioSource.ended) {
        setAiAudioText(null);
        setAiSpeech(false);
      }
    }
  }, [audioSource]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenData = urlParams.get("token");

    if (tokenData) {
      token.set(tokenData);
      GetUser();
      GetLatestUserJobDetails();
      GetUserLatestInterviews();

      urlParams.delete("token");

      // Modify the browser's URL without triggering a page reload
      const newUrl = window.location.pathname + urlParams.toString();
      window.history.replaceState({}, document.title, newUrl);
    }
  });

  const handleShowInterview = () => {
    setShowPerformance(false);
    setShowSettings(false);
    setShowInterview(true);
  };

  const handleShowSettings = () => {
    setShowPerformance(false);
    setShowInterview(false);
    setShowSettings(true);
  };

  const handleShowPerformance = () => {
    setShowInterview(false);
    setShowSettings(false);
    setShowPerformance(true);
  };

  const sendMessage = async () => {
    setAiAudioText(null);
    setAiSpeech(false);
    if (messages.length >= 22) {
      alert("Interview is over");
      return;
    }
    if (userInput.trim() === "") {
      return;
    }

    // For user message
    const newMessage = { text: userInput, user: "user" };
    messages.push(newMessage);
    setMessages(messages);
    const onlyAiQuestions = messages.filter((message) => message.user === "ai");
    setUserInput("");
    if (messages.length === 22) {
      localStorage.removeItem("messages");
      setIsAIWriting(true);
      saveInterviews(
        onlyAiQuestions[onlyAiQuestions.length - 1].text,
        newMessage.text
      );
      const aiMessage = {
        text: `Congratulations, you have completed your interview questions.`,
        user: "ai",
      };
      setAiAudioText(aiMessage.text);
      setAiSpeech(true);
      messages.push(aiMessage);
      setMessages(messages);
      setMessageIndicatorIndex(messages[messages.length - 1]);
      setHideTextArea(true);
      stopRecording();
      setComplete(true);
      localStorage.setItem("completed", true);
      setIsAIWriting(false);
      return;
    }
    setIsAIWriting(true);

    if (!cvData) {
      await updateCvData();
    }

    const prompt2 = `
    Conduct a real-time mock interview for the ${
      jobDetails.jobPosition
    } position. Ask the next critical interview question without caring about the user's response given you have asked the following are questions you already asked the interviewee:-"${onlyAiQuestions
      .map((question) => question.text)
      .join(",")}. Please follow the following rules:
      \n1. Do not ask duplicated questions from the ones you have already asked.
      \n2. The limit of questions to be asked is 11. Make sure all relevant questions are asked.
      \n3. A question should not exceed 50 words.
      \n4. Always ask 1 question.
      \n5. Ask probing and relevant questions, adapting your language and complexity to suit a ${
        jobDetails.seniorityLevel
      } ${jobDetails.jobPosition} ${
      cvData ? `whose background is as follows: ${cvData}` : ""
    }.
      \n6. Do not show any kind of numbering or any prefixed indication of a question i.e Q.
    `;

    try {
      let aiResponse;
      let count = 0;
      while (!aiResponse && count < 2) {
        try {
          count++;
          aiResponse = await GetOpenAiTextResponse(prompt2);
        } catch (error) {
          toast(`AI encountered a problem. Retrying.`, ToastErrorComponents);
        }
      }

      if (!aiResponse) {
        setIsAIWriting(false);
        setShowSend(false);
        setRecording(false);
        setNetworkError(true);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        return;
      }
      saveInterviews(
        onlyAiQuestions[onlyAiQuestions.length - 1].text,
        newMessage.text
      );
      setAiAudioText(aiResponse);
      setAiSpeech(true);
      // For AI message
      const aiMessage = { text: aiResponse, user: "ai" };
      messages.push(aiMessage);
      setMessages(messages);
      setMessageIndicatorIndex(messages[messages.length - 1]);
      if (!networkError) {
        localStorage.setItem("messages", JSON.stringify(messages));
      }
      setIsAIWriting(false);
      setShowSend(false);
      setRecording(false);
    } catch (error) {
      setIsAIWriting(false);
      setShowSend(false);
      setRecording(false);
    }
  };

  const handleAudio = async () => {
    let chunks = [];
    if (typeof window !== "undefined" && !hideTextArea) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          const newMediaRecorder = new MediaRecorder(stream);
          newMediaRecorder.onstart = () => {
            chunks = [];
          };
          newMediaRecorder.ondataavailable = (e) => {
            chunks.push(e.data);
          };
          newMediaRecorder.onstop = async () => {
            const audioBlob = new Blob(chunks, { type: "audio/webm" });
            const audioUrl = URL.createObjectURL(audioBlob);
            const audio = new Audio(audioUrl);
            audio.onerror = function (err) {
              console.error("Error playing audio:", err);
            };
            const audioFile = new File([audioBlob], "recorded_audio.wav", {
              type: "audio/wav",
            });
            const data = await GetOpenAiAudioResponse(audioFile);
            setUserInput(data);
            setAiIsTranscribing(false);
            setShowSend(true);
          };
          setMediaRecorder(newMediaRecorder);
        })
        .catch((err) => console.error("Error accessing microphone:", err));
    }
  };

  // Function to start recording
  const startRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.start();
      setRecording(true);
    }
  };
  // Function to stop recording
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
      setAiIsTranscribing(true);
    }
  };

  const getExistingInterviews = async () => {
    try {
      let jobDesc = await userJobDescription.get();
      jobDesc = typeof jobDesc === "object" ? jobDesc : JSON.parse(jobDesc);
      if (jobDesc._id !== "") {
        await RefreshLoginToken();
        const getExistingInterviews =
          await GetJobDetailInterviewQuestionsAndAnswers(jobDesc._id, 1, 30);
        if (getExistingInterviews.length >= 11) {
          const existingMessages = [];
          for (const interview of getExistingInterviews) {
            existingMessages.push({ text: interview.aiQuestion, user: "ai" });
            existingMessages.push({
              text: interview.userResponse,
              user: "user",
            });
          }
          existingMessages.push({
            text: `Congratulations, you have completed your interview questions.`,
            user: "ai",
          });
          setMessages(existingMessages);
          setHideTextArea(true);
          localStorage.setItem("completed", true);
        }
      }
    } catch (e) {}
  };

  const saveInterviews = async (aiQuestion, userResponse) => {
    try {
      await RefreshLoginToken();
      await SaveNewInterviewResponses(jobDetails._id, aiQuestion, userResponse);
    } catch (e) {
      console.log(e);
    }
  };

  const handleKeyPress = (e) => {
    // Check if the pressed key is "Enter" (key code 13)
    if (e.key === "Enter") {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleAiAudio = async () => {
    if (aiAudioText && aiSpeech) {
      try {
        setMessageIndicatorIndex(messages[messages.length - 1]);
        const audioBlob = await GetOpenAiSpeechResponse(aiAudioText);

        if (audioBlob) {
          const byteCharacters = atob(audioBlob); // Decode Base64 to binary string

          // Convert binary string to ArrayBuffer
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: "audio/mpeg" });

          // Create an audio element and play the audio
          const audio = new Audio(URL.createObjectURL(blob));
          audio.onerror = function (err) {
            console.error("Error playing audio:", err);
          };
          await audio.play();
          setAudioSource(audio);
          setAiAudioText(null);
          setAiSpeech(false);
          setMessageIndicatorIndex(null);
        }
      } catch (e) {
        setMessageIndicatorIndex(null);
        setAiSpeech(false);
        setAiAudioText(null);
        console.log(e);
      }
    }
  };

  const updateCvData = async () => {
    try {
      if (!cvData) {
        await RefreshLoginToken();
        const data = await GetUser();
        if (data.cvDataExtraction) {
          setCvData(data.cvDataExtraction);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (jobDetails._id !== "" && !cvData) {
      updateCvData();
    }
  }, [cvData]);

  return (
    <div className="industry-body flex flex-row w-full min-h-screen bg-[#141718] m-0 p-0">
      <div className="left-nav flex flex-col w-[300px] mt-8 ml-4">
        <div className="flex flex-row gap-2">
          <img
            className="industry-logo h-[25px] w-[25px]"
            src={TopLogo}
            alt=""
          />
          <img
            className="industry-logo-text h-[15px] w-[50px] mt-[5px]"
            src={TopLogoText}
            alt=""
          />
        </div>
        <div
          className="new-interview-div flex flex-row justify-evenly items-center w-[160px] h-[40px] rounded-lg border-[1px] border-solid border-[#3C3D41] bg-[#FFF] gap-2 mt-[30px] cursor-pointer"
          onClick={() => {
            navigate("/startInterview");
          }}
        >
          <img className="add-box-img h-[24px] w-[24px]" src={AddBox} alt="" />
          <p className="new-interview-text text-sm font-normal text-[#000]">
            New Interview
          </p>
        </div>
        <div
          onClick={handleShowInterview}
          className={`interview-div flex flex-row justify-start items-center w-[240px] h-[40px] rounded-lg  ${
            showInterview ? "bg-[#ffffff26]" : ""
          } gap-2 mt-[30px] cursor-pointer`}
        >
          <img
            className="chat-bubble-img h-[24px] w-[24px] ml-[20px]"
            src={ChatBubble}
            alt=""
          />
          <p className="interview-text text-sm font-normal text-[#fff]">
            Interview
          </p>
        </div>
        <div
          onClick={handleShowPerformance}
          className={`performance-review-div flex flex-row justify-start items-center gap-2 mt-[30px] cursor-pointer w-[240px] h-[40px] rounded-lg  ${
            showPerformance ? "bg-[#ffffff26]" : ""
          }`}
        >
          <img
            className="analytics-img h-[24px] w-[24px] ml-[20px]"
            src={Analytics}
            alt=""
          />
          <p className="performance-review-text text-sm font-normal text-[#fff]">
            Performance review
          </p>
        </div>
        {/* <div className="flex flex-row justify-start items-center gap-2 mt-[30px] cursor-pointer">
          <img
            className="archive-img h-[24px] w-[24px] ml-[20px]"
            src={Archive}
            alt=""
          />
          <p className="archives-text text-sm font-normal text-[#fff]">
            Archives
          </p>
        </div> */}
        <div
          onClick={handleShowSettings}
          className={`flex flex-row justify-start items-center gap-2 mt-[30px] cursor-pointer w-[240px] h-[40px] rounded-lg  ${
            showSettings ? "bg-[#ffffff26]" : ""
          }`}
        >
          <img
            className="settings-img h-[24px] w-[24px] ml-[20px]"
            src={Settings}
            alt=""
          />
          <p className="settings-text text-sm font-normal text-[#fff]">
            Settings
          </p>
        </div>
      </div>

      {showInterview && (
        <div className="dashboard-div flex flex-col w-[1010px] mt-8 bg-[#232627] rounded-t-[20px]">
          <p className="software-header text-xl font-medium text-[#FFF] mt-[10px] ml-[20px]">
            {jobDetails.jobPosition}
          </p>
          <hr className="dashboard-line w-full h-[1px] border-[#343839] mt-4" />
          <div className="chat-container" ref={chatContainerRef}>
            {messages.length > 0 &&
              messages.map((message, index) => (
                <div
                  key={index}
                  className="main-dashboard flex flex-col ml-[20px] mt-[20px]"
                >
                  {message.user === "ai" && (
                    <div className="intro-div flex flex-row justify-between items-center w-[1000px] h-[100px] bg-[#141718] rounded-[21px]">
                      <img
                        className="intro-div-logo h-[50px] w-[50px] ml-[15px]"
                        src={DashboardLogo}
                        alt=""
                      />
                      <p className="welcome-text font-normal text-[#FFF]  text-left">
                        {message.text}
                      </p>
                      <div
                        onClick={() => {
                          setMessageIndicatorIndex(index);
                          setAiSpeech(!aiSpeech);
                          setAiAudioText(message.text);
                        }}
                      >
                        {index !== messageIndicatorIndex && (
                          <img
                            className="sound-img h-[24px] w-[25px] mr-[20px] cursor-pointer"
                            src={Sound}
                            alt=""
                          />
                        )}
                        {aiSpeech && index === messageIndicatorIndex && (
                          <img
                            className="sound-img h-[24px] w-[25px] mr-[20px] cursor-pointer"
                            src={OtherSound}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {message.user === "user" && (
                    <div className="reply-div flex flex-row justify-between items-center bg-[#2C2F31] rounded-[21px] mt-[20px]">
                      <p className="user-reply-msg text-sm font-normal text-[#FFF] ml-[20px] w-[800px]">
                        {message.text}
                      </p>

                      <img
                        className="user-img w-[20px] mr-[10px] mt-[-20px]"
                        src={user && user?.avatar ? user.avatar : UserImage}
                        alt=""
                      />
                    </div>
                  )}
                </div>
              ))}
            {isAIWriting && (
              <div className="main-dashboard">
                <div className="voice-record-div flex flex-row justify-between items-center w-[1000px] h-[100px] bg-[#141718] rounded-[21px] mt-[20px]">
                  <div className="flex flex-row">
                    <img
                      className="intro-div-logo h-[50px] w-[50px] ml-[15px]"
                      src={DashboardLogo}
                      alt=""
                    />
                    {/* <img
                    className="loading-icon h-[8px] w-[40px] ml-[20px] mt-2"
                    src={Loading}
                    alt=""
                  /> */}
                    <div className="typing-indicator">
                      <span className="dot dot1"></span>
                      <span className="dot dot2"></span>
                      <span className="dot dot3"></span>
                    </div>
                  </div>
                  <img
                    className="sound-img h-[24px] w-[25px] mr-[20px] cursor-pointer"
                    src={OtherSound}
                    alt=""
                  />
                </div>
                {/* <div className="pause-record-section flex flex-row justify-end mt-[5px] mr-[40px]">
                <div className="pause-record-div flex flex-row justify-around items-center bg-[#141718] w-[140px] h-[25px] rounded-[4px]">
                  <img
                    className="pause-icon h-[16px] w-[16px]"
                    src={PauseIcon}
                    alt=""
                  />
                  <p className="pause-generation-text text-[12px] text-[#fff] font-semibold">
                    Pause generation
                  </p>
                </div>
              </div> */}
              </div>
            )}
          </div>
          {networkError && (
            <div className="pause-record-section flex flex-row mt-[15px] ml-[20px]">
              <div className="pause-record-div flex flex-row justify-around items-center bg-[#ef0101] w-[140px] h-[25px] rounded-[4px]">
                <p className="pause-generation-text text-[12px] text-[#fff] font-semibold">
                  AI Network Error
                </p>
              </div>
            </div>
          )}
          {!hideTextArea && (
            <div className="voice-input-div flex flex-row max-w-[930px] justify-between min-h-[50px] rounded-[15px] bg-[#141718] mt-[20px] ml-[10px]">
              <textarea
                ref={inputRef}
                className="w-[880px] resize-none bg-[#141718] rounded-[20px] focus:outline-none"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                onFocus={() => {
                  setShowSend(true);
                  setRecording(false);
                }}
                onBlur={() => {
                  if (!userInput.trim()) {
                    setShowSend(false);
                    setRecording(false);
                  }
                }}
                onKeyDown={handleKeyPress}
                disabled={isAIWriting}
              ></textarea>
              {recording && (
                <div className="mr-[400px] typing-indicator">
                  <span className="mr-[20px] listening-color">Listening</span>
                  <span className="dot dot1"></span>
                  <span className="dot dot2"></span>
                  <span className="dot dot3"></span>
                </div>
              )}

              {aiIsTranscribing && (
                <div className="mr-[400px] typing-indicator">
                  <span className="mr-[20px] processing-color">Processing</span>
                  <span className="dot dot1"></span>
                  <span className="dot dot2"></span>
                  <span className="dot dot3"></span>
                </div>
              )}
              {!showSend && !recording && (
                <img
                  onClick={recording ? stopRecording : startRecording}
                  className={`mic-img ${
                    recording ? "clicked" : ""
                  }  h-[38px] w-[44px] cursor-pointer`}
                  src={Mic}
                  alt=""
                  hidden={isAIWriting}
                />
              )}

              {!showSend && recording && (
                <IconButton
                  onClick={stopRecording}
                  className="bg-[#141718] cursor-pointer"
                >
                  <RecordIcon
                    className={`text-white record-icon ${
                      recording ? "clicked" : ""
                    }`}
                  />
                </IconButton>
              )}

              {showSend && (
                <IconButton
                  onClick={sendMessage}
                  className="bg-[#141718] cursor-pointer"
                >
                  <SendIcon className="text-white" />
                </IconButton>
              )}
            </div>
          )}
          <ConfettiComponent trigger={complete} />
        </div>
      )}

      {showPerformance && <Performance />}

      {showSettings && <SettingsComponent />}
    </div>
  );
};

export default Dashboard;
